import {
	LayerType,
	Listing,
	ListingSchema,
	Parcel,
	ParcelSchema,
	Prospect,
	ProspectSchema,
	DevelopmentPotential,
	Transaction,
	TransactionSchema,
	Municipality,
	MunicipalitySchema,
	TransactionOverrides,
} from "@/model/DataModel.ts"
import * as Sentry from "@sentry/browser"
import axios from "redaxios"

function urljoin(...parts: Array<string | URL>): URL {
	const sanitized = parts.map(p => {
		p = p.toString()
		p = p.trim()
		p = p.replace(/\/$/, "")
		p = p.replace(/^\//, "")

		return p
	})
	return new URL(sanitized.join("/"))
}

export function processListingData(listingItem: any): Listing | undefined {
	let newListing: Listing = {
		id: listingItem.id,
		hubspot_record_id: listingItem.hubspot_record_id,
		type: listingItem.listing_type,
		typeDev: listingItem.development_type.replaceAll(",", ", "),
		typeDevArray: listingItem.development_type.split(","),
		superficy: listingItem.analysis_land_area || 0,
		latitude: listingItem.latitude,
		longitude: listingItem.longitude,
		constructible: listingItem.analysis_buildable_area || 0,
		price: listingItem.listing_asking_price || 0,
		pricePerSqft: listingItem.listing_asking_price / listingItem.analysis_land_area,
		pricePerConstructibleSqft: listingItem.listing_asking_price / listingItem.analysis_buildable_area,
		link: listingItem.listing_webpage_url,
		address: listingItem.lot_address,
		city: listingItem.lot_city,
		state: listingItem.lot_state,
		postal_code: listingItem.lot_postal_code,
		image: listingItem.lot_image_url,
		off_market_radius: Number(listingItem.off_market_radius),
		sales_process_status: listingItem.sales_process_status,
		launch_date: undefined,
		launch_date_ms: undefined,
		call_for_bids: Boolean(listingItem.call_for_bids),
		broker_email: listingItem.broker_email,
		broker_first_name: listingItem.broker_first_name,
		broker_last_name: listingItem.broker_last_name,
		broker_phone_number: listingItem.broker_phone_number,
		broker_picture_url: listingItem.broker_picture_url,
	}

	if (listingItem.launch_date) {
		newListing.launch_date = new Date(listingItem.launch_date)
		newListing.launch_date_ms = newListing.launch_date.getTime()
	} else {
		newListing.launch_date_ms = 0
	}

	if (Number.isNaN(newListing.pricePerSqft)) {
		newListing.pricePerSqft = null
	}

	if (Number.isNaN(newListing.pricePerConstructibleSqft)) {
		newListing.pricePerConstructibleSqft = null
	}

	if (newListing.type === LayerType.landerz && !newListing.link) {
		console.warn(`Listing id:${listingItem.id} hubspot_id:${listingItem.hubspot_record_id} is missing a link`)
		Sentry.captureMessage(`Listing id:${listingItem.id} hubspot_id:${listingItem.hubspot_record_id} is missing a link`)
		newListing.link = "https://en.landerz.ca/listings/pagenotfound"
	}

	// validate the listing based on the zod schema
	let validatedListing = ListingSchema.safeParse(newListing)

	// todo: move this validtion on the server side
	if (newListing.latitude == 0 || newListing.longitude == 0) {
		validatedListing.success = false
	}

	if (!validatedListing.success) {
		console.error("Error while parsing listing received from the API:", newListing)

		Sentry.captureMessage("Error while parsing listing received from the API: " + newListing.id)

		console.error(validatedListing.error)
		return undefined
	} else {
		return validatedListing.data
	}
}

export async function getListings(): Promise<Array<Listing>> {
	const endpoint = import.meta.env.VITE_LISTING_API_ENDPOINT
	try {
		const result = await axios.get(endpoint)
		const rawListings: any[] = result.data

		const validatedListings: Listing[] = []

		for (let rawListing of rawListings) {
			let validatedListing = processListingData(rawListing)
			if (validatedListing) {
				validatedListings.push(validatedListing)
			}
		}
		return validatedListings
	} catch (error: any) {
		throw new Error(error)
	}
}

export async function getMunicipalities(): Promise<Municipality[]> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT

	try {
		// prettier-ignore
		const response = await axios.post<{ data: { municipalities: Municipality[] } }>(
            endpoint,
            {
                query: `query GetMunicipalities {
                    municipalities {
                        name
                    }
                }`
            }
        )

		// Data mapping
		const municipalities: Municipality[] = response.data.data.municipalities.map(municipality => ({
			name: municipality["name"],
		}))

		// Validation
		const validMunicipalities = municipalities.filter(municipality => {
			const validated = MunicipalitySchema.safeParse(municipality)

			if (!validated.success) {
				console.error("Invalid object data:", validated.error.issues)
				return false
			}
			return true
		})

		return validMunicipalities
	} catch (error: unknown) {
		throw new Error(`Error fetching municipalities: ${(error as Error).message}`)
	}
}

const transactionQueryData = `
    presentationID
    transactionID
    parcels {
      parcelID
      isActive
    }
    deedOfSaleDate
    seller {
      id
      name
      adresse
      isMoral
      neq
      representatives
      type
      activity
    }
    buyer {
      id
      name
      adresse
      isMoral
      neq
      representatives
      type
      activity
    }
    location {
      coordinates {
        latitude
        longitude
      }
      address
      municipality
      borough
    }
    superficy {
      squareMeters
      squareFeet
    }
    price
    isLandDevelopment
    id
    inscriptionNumber
    date
    psaDate
    type
    salePrice
    address
    city
    postalCode
    saleContext
    broker
    agency
    marketplace
    marketplaceId
    mortgage
    lender
    lenderAmount
    pricePerLandSqft
    pricePerDevelopableSqft
    pricePerBuildableSqft
    pricePerUnit
    landSubtype
    authorizedUses
    currentState
    tod
    zoningPlanLink
    zoningGridLink
    urbanPlanLink
    padLink
    otherZoningDocumentLink
    codificationType
    projectApproval
    projectApprovalAtDeedOfSale
    projectDocument
    projectLink
    project3dModelLink
    projectTypes
    stories
    siteCoverageRatio
    density
    buildableAreaSqft
    numberOfUnits
    parkingOutdoorCount
    parkingIndoorCount
    parkingTotal
    parkingRatio
    projectComments
    unitType
    unitAreaSqft
    cptaq
    urbanPerimeter
    infrastructure
    landShape
    parcelReport
    contamination
    contaminationCost
    wetlands
    floodableZone
    endangeredSpecies
    deductionRatio
    deductionComments
    deductionFiles
    developableLandAreaSqft
    infrastructureDeductionRatio
    infrastructureDeductionComments
    netLandAreaSqft
    category
    metadataStatus
`

export async function getTransactionByPresentationId(apiToken: string, id: number): Promise<Transaction | null> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT

	// prettier-ignore
	const response = await axios.post(
        endpoint,
        {
            query: `query TransactionByPresentationID($id: ID!) {
            transactionByPresentationID(id: $id) {
                ${transactionQueryData}
            }
            }`,
            variables: {
                id: id,
            },
        },
        {
            headers: {
                "x-api-token": apiToken,
            },
        }
    );

	if (response.data.errors) {
		response.data.errors.forEach(({ message }) => {
			console.error(message)
		})
		return null
	}

	// Validation and Data mapping
	const transactionData = response.data.data.transactionByPresentationID
	if (!transactionData) {
		return null
	}

	const result = TransactionSchema.safeParse(transactionData)

	if (!result.success) {
		console.error("Invalid object data:", result.error.issues)
		return null
	}

	return result.data
}

export async function getTransactionsByDocumentNumber(apiToken: string, num: string): Promise<Transaction[]> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT

	// prettier-ignore
	const response = await axios.post(
        endpoint,
        {
            query: `query TransactionsByDocumentNumber($documentNumber: String!) {
                transactionsByDocumentNumber(documentNumber: $documentNumber) {
                    ${transactionQueryData}
                }
            }`,
            variables: {
                documentNumber: num,
            },
        },
        {
            headers: {
                "x-api-token": apiToken,
            },
        }
    )

	if (response.data.errors) {
		response.data.errors.forEach(({ message }) => {
			console.error(message)
		})
		return []
	}

	// Validation and Data mapping
	const transactions: Transaction[] = response.data.data.transactionsByDocumentNumber
		.map(tr => {
			const result = TransactionSchema.safeParse(tr)

			if (!result.success) {
				console.error("Invalid object data:", result.error.issues)
				return null
			}
			return result.data
		})
		.filter(Boolean)

	return transactions
}

export async function getTransactions(apiToken: string): Promise<Transaction[]> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT

	// prettier-ignore
	const response = await axios.post(
        endpoint,
        {
            query: `query SearchTransactions {
                searchTransactions { ${transactionQueryData} }
            }`,
        },
        {
            headers: {
                "x-api-token": apiToken,
            },
        }
    )

	// Validation and Data mapping
	const transactions: Transaction[] = response.data.data.searchTransactions
		.map(tr => {
			const result = TransactionSchema.safeParse(tr)

			if (!result.success) {
				console.error("Invalid object data:", result.error.issues)
				return null
			}
			return result.data
		})
		.filter(Boolean)

	return transactions
}

export async function updateTransaction(
	apiToken: string,
	inscriptionNumber: string,
	presentationId: string,
	overrides: Partial<Transaction>
): Promise<boolean> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT
	const overridesWithMetaInfo = {
		inscriptionNumber,
		presentationId: Number(presentationId),
		...overrides,
	}

	console.debug("transaction overrides", overridesWithMetaInfo)

	// prettier-ignore
	const response = await axios.post(
        endpoint,
        {
            query: `mutation UpdateTransaction($inscriptionNumber: String!, $overrides: TransactionOverrides!) {
                updateTransaction(inscriptionNumber: $inscriptionNumber, overrides: $overrides)
            }`,
            variables: {
                inscriptionNumber,
                overrides: overridesWithMetaInfo,
            },
        },
        {
            headers: {
                'x-api-token': apiToken,
            },
        }
    );

	if (response.data.errors) {
		response.data.errors.forEach(({ message }) => {
			console.error(message)
		})
		return false
	}

	return response.data.data.updateTransaction
}

// graphql query to get a parcel by its ID
export async function getParcel(parcelID: number): Promise<Parcel | null> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT
	const variables = { parcelID }
	const query = `
	query ($parcelID: String!) {
		parcel(parcelID: $parcelID) {
		  parcelID,
		  location {
			coordinates {
			  latitude,
			  longitude
			}
		  }
		}
	  }                       
      `

	try {
		const result = await axios.post(endpoint, {
			query,
			variables,
		})

		const errors = result.data.errors

		if (result.status === 200) {
			if (!errors) {
				let parcelValidation = ParcelSchema.safeParse(result.data.data.parcel)

				if (parcelValidation.success) {
					const parcel = parcelValidation.data
					parcel.location.coordinates.latitude = parseFloat(parcel.location.coordinates.latitude)
					parcel.location.coordinates.longitude = parseFloat(parcel.location.coordinates.longitude)

					return parcel
				} else {
					console.error("Invalid object data:", result)
					return null
				}
			} else {
				console.error(`GraphQL error: ${errors[0].message}`)
				return null
			}
		} else {
			console.error("Error: Parcel not found", result.status)
			return null
		}
	} catch (error: any) {
		console.error("Error.", error)
		Sentry.captureMessage(`Network error getParcel id:${parcelID}: ${error}`, "error")
		return null
	}
}

// graphql query to get a prospect by parcel id
export async function getProspect(apiToken: string, parcelID: number): Promise<Prospect | null> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT
	const variables = { parcelID }
	const query = `
        query Prospect($parcelID: String!) {
            prospect(parcelID: $parcelID) {
                prospectID
                potential
            }
        }
    `

	try {
		const result = await axios.post(
			endpoint,
			{
				query,
				variables,
			},
			{
				headers: {
					"x-api-token": apiToken,
				},
			}
		)

		const errors = result.data.errors

		if (result.status === 200) {
			if (errors) {
				console.error(`GraphQL error: ${errors[0].message}`)
				return null
			}

			const prospect = result.data.data.prospect
			if (!prospect) {
				return null
			}

			const validatedProspect = ProspectSchema.safeParse(prospect)

			if (validatedProspect.success) {
				return validatedProspect.data
			} else {
				console.error("Invalid object data:", result)
				return null
			}
		} else {
			console.error("Error: Prospect not found", result.status)
			return null
		}
	} catch (error: any) {
		console.error("Error.", error)
		Sentry.captureMessage(`Network error getProspect id:${parcelID}: ${error}`, "error")
		return null
	}
}

// graphql mutation to update a prospect potential by parcel id
export async function setProspectPotential(
	apiToken: string,
	parcelID: number,
	potential: DevelopmentPotential
): Promise<boolean> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT
	const query = `
        mutation {
            updateProspectPotential(parcelID: "${parcelID}", potential: ${potential})
        }
    `

	try {
		const result = await axios.post(
			endpoint,
			{
				query,
			},
			{
				headers: {
					"x-api-token": apiToken,
				},
			}
		)

		if (result.status === 200) {
			const errors = result.data.errors

			if (errors) {
				console.error(`GraphQL error: ${errors[0].message}`)
				return false
			}
			return true
		} else {
			console.error("Error: could not update prospect potential", result.status)
			return false
		}
	} catch (error: any) {
		console.error("Error.", error)
		Sentry.captureMessage(`Network error setProspectPotential id:${parcelID}: ${error}`, "error")
		return false
	}
}

/** Login with the provided credentials and get a api token */
export async function getApiToken(username: string, password: string) {
	const endpoint = import.meta.env.VITE_AUTH_API_ENDPOINT
	const payload = {
		data: {
			username: username,
			password: password,
		},
	}
	try {
		const result = await axios.post(endpoint + "login", payload)
		const token = result.data.data.token
		return token
	} catch (error: any) {
		throw new Error(error)
	}
}

export async function getShapefile(apiToken: string, parcelID: string): Promise<[string, string]> {
	const endpoint = import.meta.env.VITE_GEOM_API_ENDPOINT
	const filename = `parcel-${parcelID}-shapefile.zip`
	const url = urljoin(endpoint, filename)
	try {
		const response = await axios.get(url.toString(), {
			headers: {
				"x-api-token": apiToken,
			},
			responseType: "blob",
		})

		return [response.data, filename]
	} catch (error: any) {
		throw new Error(error)
	}
}
